<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex align-items-center justify-center pb-1">
          <feather-icon icon="UserIcon" size="16" />
          <h4 class="pl-1" style="padding-top: 8px;">
            {{ $t('general_info') }}
          </h4>
        </div>
        <validation-observer ref="companyValidation">
          <b-row>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="company.name"
                :label="$t('firstname')"
              ></vi-input
            ></b-col>
            <b-col cols="6"> </b-col>
          </b-row>
          <b-row class="mt-1 mb-1">
            <b-col cols="6">
              <b-row>
                <b-col cols="3">
                  <b-form-group>
                    <label>{{ $t('Speed monitor') }}</label
                    ><br />
                    <b-form-checkbox
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      v-model="company.speedingModule"
                  /></b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="ml-1">
                    <label>{{ $t('Cyclist counter') }}</label
                    ><br />
                    <b-form-checkbox
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      v-model="company.cyclistsModule"
                  /></b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="ml-1">
                    <label>{{ $t('Vehicle counter') }}</label
                    ><br />
                    <b-form-checkbox
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      v-model="company.vehiclesModule"
                  /></b-form-group>
                </b-col>
                <b-col cols="3">
                  <b-form-group class="ml-1">
                    <label>{{ $t('radars') }}</label
                    ><br />
                    <b-form-checkbox
                      class="mr-0 mt-50"
                      name="is-rtl"
                      switch
                      inline
                      v-model="company.radarModule"
                  /></b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button @click="confirm" variant="primary">{{ $t('save') }}</b-button>
      </div>
    </b-card>
    <permissions
      actionGet="devices/GET_DEVICES_WITH_COMPANY_ABILITES"
      actionSave="devices/SAVE_DEVICE_COMPANY_ABILITES"
      v-if="$route.params.id"
    ></permissions>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import Permissions from './Permissions.vue';
import ViSelect from '../../components/ViSelect.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, watch, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const companyId = context.root.$route.params.id;

    const companyValidation = ref(null);

    const company = ref({
      email: null,
      firstName: null,
      lastName: null,
      password: null,
      repeatPassword: null,
      roleId: 3,
    });

    onMounted(async () => {
      if (companyId) {
        const response = await store.dispatch(
          'companies/GET_COMPANY',
          companyId,
        );
        company.value = response.data;
      }
    });

    const confirm = async () => {
      const validation = await companyValidation.value.validate();
      if (companyId || validation) {
        if (
          company.value.password &&
          company.value.password != company.value.repeatPassword
        ) {
          dangerToast(
            toastInstance,
            i18n.t('warning'),
            i18n.t('profile.no_matching_password'),
          );
        } else {
          let { password, repeatPassword, ...u } = company.value;
          if (company.value.password) u.password = company.value.password;

          const response = await store.dispatch(
            companyId ? 'companies/UPDATE_COMPANY' : 'companies/CREATE_COMPANY',
            {
              companyId,
              company: u,
            },
          );
          if (response) {
            successToast(
              toastInstance,
              i18n.t('success'),
              companyId ? i18n.t('company_updated') : i18n.t('company_created'),
            );
            context.root.$router.push({ name: 'companies' });
          } else {
            dangerToast(
              toastInstance,
              i18n.t('failed'),
              companyId
                ? i18n.t('profile.failed')
                : i18n.t('company_creation_failed'),
            );
          }
        }
      }
    };

    return { company, companyId, confirm, companyValidation };
  },
};
</script>

<style></style>
